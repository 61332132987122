import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export const SET_LOADING = "SET_LOADING";
export function setLoading(isLoading) {
    return {
        type: SET_LOADING,
        payload: isLoading,
    };
}

//NEU

export function createOrder(orderTypesId) {
    return function (dispatch) {
        dispatch(setLoading(true));
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/createOrder/${orderTypesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setOrder(json));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export function addArticleToOrder(ordersId, articlesId, cartItemId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/addArticleToOrder/${ordersId}/${articlesId}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    dispatch(deleteTempTransaction(cartItemId));
                }
                return handleResponse(response);
            })
            .then((json) => {
                dispatch(checkOrderDependenciesFulfilled(ordersId));
                dispatch(getOrderSums(ordersId));
                return json;
            });
    };
}

export function duplicateItem(orderId, transactionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/duplicateArticle/${orderId}/${transactionId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(getOrderSums(orderId));
                dispatch(checkOrderDependenciesFulfilled(orderId));
                return json;
            });
    };
}

export function checkOrderDependenciesFulfilled(orderId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(orderId),
        };
        return fetch(config.backendHost + "/transactions/checkOrderDependenciesFulfilled", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateOrderValidation(json));
                return json;
            });
    };
}

export function getTransaction(transactionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/transactions/getTransaction/" + transactionId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getOrderSums(orderId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/getOrderSums/${orderId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                console.log(json);
                dispatch(updateOrderSums(json));
                return json;
            });
    };
}

export function updateOrderDiscount(orderId, value, isMonetary) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ orderId: orderId, discountValue: value, isMonetary: isMonetary }),
        };
        return fetch(`${config.apiUrl}/setOrderDiscount`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(getOrderSums(orderId));
                return json;
            });
    };
}

export function createTransaction(orderTypesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(orderTypesId),
    };
    return function (dispatch) {
        dispatch(setLoading(true));
        return fetch(config.backendHost + "/transactions/createTransaction", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                json.Transactions = [];
                dispatch(setOrder(json));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export function updateTransactionComment(transactionId, comment) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ transactionId: transactionId, comment: comment }),
        };
        return fetch(config.apiUrl + "/transactions/updateTransactionComment", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllTransactions(loadOptions, daysBefore) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(daysBefore),
        };

        let query = new URLSearchParams(loadOptions).toString();

        return fetch(config.backendHost + "/transactions/getAllTransactions?" + query, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getTransactionSums(transactionId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(transactionId),
        };
        return fetch(config.backendHost + "/transactions/getTransactionSums", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateOrderSums(json));
                return json;
            });
    };
}

export function getTransactionById(transactionId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(transactionId),
        };
        return fetch(config.backendHost + "/transactions/getTransactionById", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function cancelTransaction(transactionId, reason) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: transactionId, reason: reason }),
        };
        return fetch(config.backendHost + "/transactions/cancelTransaction", requestOptions).then((response) =>
            handleResponse(response).then(() => {
                dispatch(unsetOrder());
            })
        );
    };
}

export function bookAndSetDoneTransaction(transactionId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(transactionId),
        };
        return fetch(config.backendHost + "/transactions/bookAndSetDoneTransaction", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function changeTransactionAmount(ordersId, transactionId, amount) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                transactionId: transactionId,
                amount: amount,
            }),
        };
        return fetch(config.backendHost + "/Orders/changeOrderDetailAmount", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateTransactionAmount(json));
                return json;
            })
            .then((json) => {
                dispatch(getOrderSums(ordersId));
                dispatch(checkOrderDependensiesFullfilled(ordersId));
                return json;
            });
    };
}

export function updateTransactionValues(updateTransactionDto) {
    console.log(updateTransactionDto);
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(updateTransactionDto),
        };
        return fetch(config.backendHost + "/Transactions/updateTransaction", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

//ALT

// export function createOrder(orderTypesId) {
//     return function (dispatch) {
//         dispatch(setLoading(true));
//         const requestOptions = {
//             method: "POST",
//             headers: authHeader(),
//             body: JSON.stringify(orderTypesId),
//         };
//         return fetch(config.backendHost + "/Orders/createOrder", requestOptions)
//             .then((response) => handleResponse(response))
//             .then((json) => {
//                 dispatch(setOrder(json));
//                 dispatch(setLoading(false));
//                 return json;
//             });
//     };
// }

export const SET_ORDER = "SET_ORDER";
export function setOrder(json) {
    return {
        type: SET_ORDER,
        payload: json,
    };
}

export function createDailyOrdersReport(date) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(date),
        };
        return fetch(config.backendHost + "/Reports/printDailyOrders", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getArticleGroupsForOrderManagement() {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagement", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function getArticleGroupsForOrderManagementByParentId(articleGroupsId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        if (articleGroupsId === null) {
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagementByParentId/0",
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    return json;
                });
        } else {
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupsForOrderManagementByParentId/" + articleGroupsId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    return json;
                });
        }
    };
}

export function getRootArticleGroupsForOrderManagement() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getRootArticleGroupsForOrderManagement", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function changeOrderDetailAmount(ordersId, orderDetailsId, amount) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                orderDetailsId: orderDetailsId,
                amount: amount,
            }),
        };
        return fetch(config.backendHost + "/Orders/changeOrderDetailAmount", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateTransactionAmount(json));
                return json;
            })
            .then((json) => {
                dispatch(getOrderSums(ordersId));
                dispatch(checkOrderDependensiesFullfilled(ordersId));
                return json;
            });
    };
}

export const UPDATE_TRANSACTION_AMOUNT = "UPDATE_TRANSACTION_AMOUNT";
export function updateTransactionAmount(json) {
    return {
        type: UPDATE_TRANSACTION_AMOUNT,
        payload: json,
    };
}

export function cancelOrder(ordersId, reason) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, reason: reason }),
        };
        return fetch(config.backendHost + "/Orders/cancelOrder", requestOptions).then((response) =>
            handleResponse(response).then(() => {
                dispatch(unsetOrder());
            })
        );
    };
}

export const UNSET_ORDER = "UNSET_ORDER";
export function unsetOrder() {
    return {
        type: UNSET_ORDER,
    };
}

// export function getOrderSums(ordersId) {
//     return function (dispatch) {
//         const requestOptions = {
//             method: "POST",
//             headers: authHeader(),
//             body: JSON.stringify(ordersId),
//         };
//         return fetch(config.backendHost + "/Orders/getOrderSums", requestOptions)
//             .then((response) => handleResponse(response))
//             .then((json) => {
//                 console.log(json);
//                 dispatch(updateOrderSums(json));
//                 return json;
//             });
//     };
// }

export const UPDATE_ORDER_SUMS = "UPDATE_ORDER_SUMS";
export function updateOrderSums(json) {
    return {
        type: UPDATE_ORDER_SUMS,
        payload: json,
    };
}

export const UPDATE_CART_SUM = "UPDATE_CART_SUM";
export function updateCartSum(json) {
    return {
        type: UPDATE_CART_SUM,
        payload: json,
    };
}

export function getOrders(daysAgo, page = 1, pageSize = 10, orderId = null) {
    return function (dispatch) {
        const params = new URLSearchParams({
            daysAgo: daysAgo.toString(),
            page: page.toString(),
            pageSize: pageSize.toString(),
        });
        if (orderId) {
            params.append("orderId", orderId.toString());
        }
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(config.apiUrl + "/getOrders?" + params.toString(), requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllOrders(loadOptions, daysBefore) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(daysBefore),
        };

        let query = new URLSearchParams(loadOptions).toString();

        return fetch(config.backendHost + "/Orders/getAllOrders?" + query, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllOrderDetailsSortedForPrint() {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getAllOrderDetailsSortedForPrint", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setDoneOrder(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/setDoneOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function bookAndSetDoneOrder(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/bookAndSetDoneOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllOrderDetails() {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getAllOrderDetails", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getOrderById(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/getOrderById", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setPackageTerminalPrintedFlagTrue(ordersId) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/setPackageTerminalPrintedFlagTrue", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function getAllDailyOrderDetails(date) {
    return function () {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(date ? date : new Date()),
        };
        return fetch(config.backendHost + "/Orders/getDailyOrderDetails", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function checkOrderDependensiesFullfilled(ordersId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(ordersId),
        };
        return fetch(config.backendHost + "/Orders/checkOrderDependensiesFullfilled", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateOrderValidation(json));
                return json;
            });
    };
}

export const UPDATE_ORDER_VALIDATION = "UPDATE_ORDER_VALIDATION";
export function updateOrderValidation(orderValidation) {
    return {
        type: UPDATE_ORDER_VALIDATION,
        payload: orderValidation,
    };
}

export function doOrder(ordersId, addressId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({ ordersId: ordersId, addressId: addressId }),
        };
        return fetch(config.backendHost + "/Orders/doOrder", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(unsetOrder());
                return json;
            });
    };
}

export function setCustomerForOrder(orderId, customerId) {
    return function (dispatch) {
        dispatch(setLoading(true));
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ orderId: orderId, customerId: customerId }),
        };
        return fetch(`${config.apiUrl}/setCustomerForOrder`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateCustomerForOrder(json));
                dispatch(checkOrderDependensiesFullfilled(orderId));
                dispatch(setLoading(false));
                return json;
            });
    };
}

export function setDeliveryAddressForOrder(orderId, addressId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ orderId: orderId, addressId: addressId }),
        };
        return fetch(`${config.apiUrl}/setDeliveryAddressForOrder`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(updateCustomerAddressForOrder(json));
                return json;
            });
    };
}

export function getRequiresCustomer(orderTypesId) {
    return function () {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/getRequiresCustomer/${orderTypesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const UPDATE_CUSTOMER_FOR_ORDER = "UPDATE_CUSTOMER_FOR_ORDER";
export function updateCustomerForOrder(json) {
    return {
        type: UPDATE_CUSTOMER_FOR_ORDER,
        payload: json,
    };
}

export const UPDATE_CUSTOMER_ADDRESS_FOR_ORDER = "UPDATE_CUSTOMER_ADDRESS_FOR_ORDER";
export function updateCustomerAddressForOrder(json) {
    return {
        type: UPDATE_CUSTOMER_ADDRESS_FOR_ORDER,
        payload: json,
    };
}

export function createCustomer(customerFormData) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(customerFormData),
        };
        return fetch(config.backendHost + "/Customers/createCustomer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateCustomer(customerFormData) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(customerFormData),
        };
        return fetch(config.backendHost + "/Customers/updateCustomer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

// export function addArticleToOrder(ordersId, articlesId, cartItemId) {
//     return function (dispatch) {
//         dispatch(setCartItemIsPending());
//         const requestOptions = {
//             method: "POST",
//             headers: authHeader(),
//             body: JSON.stringify({ ordersId: ordersId, articlesId: articlesId }),
//         };
//         return fetch(config.backendHost + "/Orders/addArticleToOrder", requestOptions)
//             .then((response) => {
//                 if (!response.ok) {
//                     dispatch(deleteTempOrderDetail(cartItemId));
//                 }
//                 return handleResponse(response);
//             })
//             .then((json) => {
//                 dispatch(checkOrderDependensiesFullfilled(ordersId));
//                 dispatch(getOrderSums(ordersId));
//                 return json;
//             });
//     };
// }

export function updateOrderComment(orderId, comment) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ orderId: orderId, comment: comment }),
        };
        return fetch(config.apiUrl + "/updateOrderComment", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const SET_TRANSACTION = "SET_TRANSACTION";
export function setTransaction(transaction) {
    return {
        type: SET_TRANSACTION,
        payload: transaction,
    };
}

export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS";
export function updateTransactions(transactions) {
    return {
        type: UPDATE_TRANSACTIONS,
        payload: transactions,
    };
}

export const ADD_ARTICLE_TO_CART = "ADD_ARTICLE_TO_CART";
export function addArticleToCart(article) {
    return {
        type: ADD_ARTICLE_TO_CART,
        payload: article,
    };
}

export const RECEIVE_ORDERTYPES_FOR_ORDER = "RECEIVE_ORDERTYPES_FOR_ORDER";
export function receiveOrderTypesForOrder(orderTypes) {
    return {
        type: RECEIVE_ORDERTYPES_FOR_ORDER,
        payload: orderTypes,
    };
}

export function updateOrderType(ordersId, orderType) {
    return function (dispatch, getState) {
        if (getState().orderManagement.rootArticleGroups.length > 0 && getState().orderManagement.isLoading) return;
        dispatch(setLoading(true));
        if (!ordersId) {
            dispatch(setOrderType(orderType));
            getState().orderManagement.rootArticleGroups = [];
            dispatch(getRootArticleGroups(orderType.id));
            dispatch(setLoading(false));
            return;
        }
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/updateOrderType/${ordersId}/${orderType.id}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setOrderType(orderType));
                dispatch(updateTransactions(json));
                dispatch(checkOrderDependensiesFullfilled(ordersId));
                dispatch(getOrderSums(ordersId));
                getState().orderManagement.rootArticleGroups = [];
                dispatch(getRootArticleGroups(orderType.id));
                return json;
            });
    };
}

export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
export function setOrderType(orderType) {
    return {
        type: SET_ORDER_TYPE,
        payload: orderType,
    };
}

export function getOrderDetail(orderDetailsId) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getOrderDetail/" + orderDetailsId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateOrderDetail(orderDetail) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                ordersId: orderDetail.ordersId,
                orderDetailsId: orderDetail.orderDetailsId,
                sizesId: orderDetail.sizesId,
                articleCount: orderDetail.articleCount,
            }),
        };
        return fetch(config.backendHost + "/Orders/updateOrderDetail", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function orderDetailSetArticleCount(orderDetail) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                ordersId: orderDetail.ordersId,
                orderDetailsId: orderDetail.orderDetailsId,
                amount: orderDetail.articleCount,
            }),
        };
        return fetch(config.backendHost + "/Orders/orderDetailSetArticleCount", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateRequestedDeliveryTime(orderId, requestedDeliveryTime) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ orderId, requestedDeliveryTime }),
        };
        return fetch(config.apiUrl + "/updateRequestedDeliveryTime", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateManualDeliveryTime(orderId, manualDeliveryTimeMin, manualDeliveryTimeMax) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ orderId, manualDeliveryTimeMin, manualDeliveryTimeMax }),
        };
        return fetch(`${config.apiUrl}/updateManualDeliveryTime`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function updateOrderPaymentType(orderId, paymentTypesId) {
    return function (dispatch) {
        const requestOptions = {
            method: "POST",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/updateOrderPaymentType/${orderId}/${paymentTypesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function removeTransaction(ordersId, transactionId, reason) {
    return function (dispatch) {
        const requestOptions = {
            method: "DELETE",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/deleteArticleFromOrder/${ordersId}/${transactionId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then(() => {
                dispatch(deleteTransaction(transactionId));
            })
            .then(() => {
                dispatch(getOrderSums(ordersId));
                dispatch(checkOrderDependenciesFulfilled(ordersId));
            });
    };
}

export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export function deleteTransaction(transactionId) {
    return {
        type: DELETE_TRANSACTION,
        payload: transactionId,
    };
}

export const DELETE_TEMP_TRANSACTION = "DELETE_TEMP_TRANSACTION";
export function deleteTempTransaction(cartItemId) {
    return {
        type: DELETE_TEMP_TRANSACTION,
        payload: cartItemId,
    };
}

export function getCurrentDeliveryDay() {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/Orders/getCurrentDeliveryDay", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export const SET_ARTICLES_LOADING = "SET_ARTICLES_LOADING";
export function setArticlesLoading(loading) {
    return {
        type: SET_ARTICLES_LOADING,
        payload: loading,
    };
}

export function getRootArticleGroups(orderTypesId) {
    return function (dispatch, getState) {
        const cachedRootGroups = getState().orderManagement.rootArticleGroups;
        if (cachedRootGroups.length > 0) {
            dispatch(setRootArticleGroups(cachedRootGroups));
            dispatch(setLoading(false));
            return;
        }
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(config.backendHost + "/ArticleGroups/getRootArticleGroups/" + orderTypesId, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(setRootArticleGroups(json));
                dispatch(setLoading(false));
            });
    };
}

export const SET_ROOT_ARTICLE_GROUPS = "SET_ROOT_ARTICLE_GROUPS";
export function setRootArticleGroups(rootArticleGroups) {
    return {
        type: SET_ROOT_ARTICLE_GROUPS,
        payload: rootArticleGroups,
    };
}

export function setSelectedArticleGroup(articleGroup, orderTypesId) {
    return function (dispatch, getState) {
        dispatch(setArticlesLoading(true));
        dispatch(updateSelectedArticleGroup(articleGroup));
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return Promise.all([
            dispatch(getArticleGroupsByParentId(articleGroup)).then((json) => {
                dispatch(cacheArticleGroups(articleGroup.articleGroupsId, json));
                dispatch(updateCurrentArticleGroups(json));
            }),
            fetch(
                config.backendHost + "/Articles/getArticlesByArticleGroupsId/" + articleGroup.articleGroupsId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(updateCurrentArticles(json));
                }),
        ]).then(() => {
            dispatch(setArticlesLoading(false));
        });
    };
}

export const CACHE_ARTICLE_GROUPS = "CACHE_ARTICLE_GROUPS";

export function cacheArticleGroups(parentId, articleGroups) {
    return { type: CACHE_ARTICLE_GROUPS, payload: { parentId, articleGroups } };
}

export function getArticleGroupsByParentId(articleGroup) {
    return function (dispatch, getState) {
        const cachedArticleGroups = getState().orderManagement.cachedArticleGroups;
        if (cachedArticleGroups[articleGroup.articleGroupsId]) {
            return Promise.resolve(cachedArticleGroups[articleGroup.articleGroupsId]);
        }

        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                parentId: articleGroup.articleGroupsId,
                relevantGroups: articleGroup.relevantGroups,
            }),
        };
        return fetch(`${config.backendHost}/ArticleGroups/getArticleGroupsByParentId`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(cacheArticleGroups(articleGroup.articleGroupsId, json));
                updateCurrentArticleGroups(json);
                return json;
            });
    };
}

export const UPDATE_SELECTED_ARTICLE_GROUP = "UPDATE_SELECTED_ARTICLE_GROUP";
export function updateSelectedArticleGroup(articleGroup) {
    return {
        type: UPDATE_SELECTED_ARTICLE_GROUP,
        payload: articleGroup,
    };
}

export const UPDATE_CURRENT_ARTICLE_GROUPS = "UPDATE_CURRENT_ARTICLE_GROUPS";
export function updateCurrentArticleGroups(articleGroups) {
    return {
        type: UPDATE_CURRENT_ARTICLE_GROUPS,
        payload: articleGroups,
    };
}

export const UPDATE_CURRENT_ARTICLES = "UPDATE_CURRENT_ARTICLES";
export function updateCurrentArticles(articles) {
    return {
        type: UPDATE_CURRENT_ARTICLES,
        payload: articles,
    };
}

export function getParentArticleGroup(articleGroup, orderTypesId) {
    return function (dispatch) {
        dispatch(setArticlesLoading(true));

        if (articleGroup.parentId) {
            const requestOptions = {
                method: "GET",
                headers: authHeader(),
            };
            return fetch(
                config.backendHost + "/ArticleGroups/getArticleGroupById/" + articleGroup.parentId,
                requestOptions
            )
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(setArticlesLoading(false));
                    dispatch(updateCurrentArticleGroups([]));
                    dispatch(updateCurrentArticles([]));
                    dispatch(setSelectedArticleGroup(json, orderTypesId));
                });
        } else {
            dispatch(setArticlesLoading(false));
            dispatch(updateCurrentArticleGroups([]));
            dispatch(updateCurrentArticles([]));
            dispatch(updateSelectedArticleGroup(null));
            dispatch(getRootArticleGroups(orderTypesId));
        }
    };
}

export const TOGGLE_SEARCH_POPUP = "TOGGLE_SEARCH_POPUP";
export function toggleSearchPopup(isSearchPopupOpen) {
    return {
        type: "TOGGLE_SEARCH_POPUP",
        payload: isSearchPopupOpen,
    };
}

export function getSearchResults(searchString) {
    return function (dispatch) {
        dispatch(setArticlesLoading(true));
        dispatch(updateSelectedArticleGroup(null));
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return Promise.all([
            fetch(config.backendHost + "/ArticleGroups/getArticleGroupsBySearchString/" + searchString, requestOptions)
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(updateCurrentArticleGroups(json));
                }),
            fetch(config.backendHost + "/Articles/getArticlesBySearchString/" + searchString, requestOptions)
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(updateCurrentArticles(json));
                }),
        ]).then(() => {
            dispatch(setArticlesLoading(false));
        });
    };
}

export function getArticleDependencies(article) {
    return function (dispatch) {
        const requestOptions = {
            method: "GET",
            headers: authHeaderForAzure(),
        };
        return fetch(`${config.apiUrl}/getArticleDependencies/${article.articleId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return { ...article, dependencyGroups: json };
            });
    };
}

export const SET_SKIP_ARTICLE_DETAILS = "SKIP_ARTICLE_DETAILS";
export function setSkipArticleDetails(skipArticleDetails) {
    return {
        type: SET_SKIP_ARTICLE_DETAILS,
        payload: skipArticleDetails,
    };
}

export const OPEN_ARTICLE_DETAILS_POPUP = "OPEN_ARTICLE_DETAILS_POPUP";
export function openArticleDetailsPopup(articleId, onSave) {
    return {
        type: OPEN_ARTICLE_DETAILS_POPUP,
        payload: { articleId: articleId, onSave: onSave },
    };
}

export const CLOSE_ARTICLE_DETAILS_POPUP = "CLOSE_ARTICLE_DETAILS_POPUP";
export function closeArticleDetailsPopup() {
    return {
        type: CLOSE_ARTICLE_DETAILS_POPUP,
        payload: { articleId: null, onSave: null },
    };
}
