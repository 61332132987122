import CalendarIcon from "@material-ui/icons/Today";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { history } from "../../../helpers/history";
import "./header.scss";
import "./guestHeader.scss";
import "moment/locale/de";
import store from "../../../store";
import {
    setCookingProcessesCalendarDate,
    showCookingProcessesCalendar,
    updateCustomerLanguageRedux,
} from "../../../actions/cookingProcessActions";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popup, ScrollView } from "devextreme-react";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import "flag-icon-css/css/flag-icon.min.css";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { getCustomerByCustomerUid, updateCustomerLanguage } from "../../../actions/customerActions";

const languages = [
    { code: "fr", name: "Francais", country_code: "fr" },
    { code: "de", name: "Deutsch", country_code: "de" },
    { code: "en", name: "English", country_code: "gb" },
    { code: "ar", name: "Arabian", country_code: "sa" },
    { code: "ba", name: "basque", country_code: "es" },
    { code: "bu", name: "bulgaria", country_code: "bg" },
    { code: "ca", name: "catalan", country_code: "es" },
    { code: "ch", name: "chinese", country_code: "cn" },
    { code: "cr", name: "croatian", country_code: "hr" },
    { code: "cz", name: "czech", country_code: "cz" },
    { code: "da", name: "danish", country_code: "dk" },
    { code: "es", name: "spanish", country_code: "es" },
    { code: "fi", name: "finnish", country_code: "fi" },
    { code: "ga", name: "gallician", country_code: "es" },
    { code: "gr", name: "greek", country_code: "gr" },
    { code: "hu", name: "hungarian", country_code: "hu" },
    { code: "it", name: "italian", country_code: "it" },
    { code: "la", name: "latvian", country_code: "lv" },
    { code: "li", name: "lithuanian", country_code: "lt" },
    { code: "nl", name: "dutch", country_code: "nl" },
    { code: "po", name: "portuguese", country_code: "pt" },
    { code: "ro", name: "romanian", country_code: "ro" },
    { code: "ru", name: "russian", country_code: "ru" },
    { code: "sl", name: "slovenian", country_code: "sl" },
    { code: "so", name: "slovakian", country_code: "sk" },
    { code: "sw", name: "swedish", country_code: "se" },
    { code: "tu", name: "turkish", country_code: "tr" },
];

class GuestHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLanguageSelection: false,
            selectedLanguage: "de",
        };
        this.handleHomeClick = this.handleHomeClick.bind(this);
        this.handleCalenderButtonClick = this.handleCalenderButtonClick.bind(this);
        this.handleOnDateClick = this.handleOnDateClick.bind(this);
        this.updateCustomerLanguage = this.updateCustomerLanguage.bind(this);
        this.showLanguageSelection = this.showLanguageSelection.bind(this);
        this.hideLanguageSelection = this.hideLanguageSelection.bind(this);
    }

    async componentDidMount() {
        const customersUid = localStorage.getItem("customersUid");
        if (customersUid) {
            const response = await store.dispatch(getCustomerByCustomerUid(customersUid));

            let selectedLanguage = response.selectedLanguage || "de";
            if (selectedLanguage.startsWith("N'") && selectedLanguage.endsWith("'")) {
                selectedLanguage = selectedLanguage.slice(2, -1);
            }

            if (this.state.selectedLanguage !== selectedLanguage) {
                this.setState({ selectedLanguage });
            }
        }
    }

    showLanguageSelection() {
        this.setState({ showLanguageSelection: true });
    }

    hideLanguageSelection() {
        this.setState({ showLanguageSelection: false });
    }

    handleHomeClick() {
        const customersUid = localStorage.getItem("customersUid");
        store.dispatch(setCookingProcessesCalendarDate(new Date()));
        if (!this.props.location.pathname.includes("allCookingProcessesCustomer")) {
            history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p/");
        }
    }

    handleCalenderButtonClick() {
        store.dispatch(showCookingProcessesCalendar(true));
    }

    handleOnDateClick() {
        const customersUid = localStorage.getItem("customersUid");
        if (!this.props.location.pathname.includes("allCookingProcessesCustomer")) {
            history.push("/guest/allCookingProcessesCustomer/" + customersUid + "/p/");
        }
    }

    updateCustomerLanguage(customersUid, code) {
        store.dispatch(updateCustomerLanguage(customersUid, code));
        store.dispatch(updateCustomerLanguageRedux(code));
        this.setState({
            selectedLanguage: code,
        });
    }

    render() {
        const { theme, cookingProcess, t, i18n } = this.props;
        const { selectedLanguage, showLanguageSelection } = this.state;
        const customersUid = localStorage.getItem("customersUid");
        moment.locale(selectedLanguage);

        return (
            <React.Fragment>
                <header
                    id="header"
                    className={this.props.identity.user.Token ? "logged-in guest" : "not-logged-in guest"}
                >
                    <div id="logo" onClick={this.handleHomeClick}>
                        {theme.logoUrl && <img src={theme.logoUrl} alt={theme.title} />}
                        <div id="logoText">{theme.title}</div>
                    </div>
                    <div id="languageButton" onClick={this.showLanguageSelection}>
                        <FontAwesomeIcon icon={faGlobe} />
                    </div>
                    <div id="calendarButton" onClick={this.handleCalenderButtonClick}>
                        {cookingProcess.cookingProcessesCalendarDate !== null && (
                            <span className="currentDate" onClick={this.handleOnDateClick}>
                                {moment(new Date(cookingProcess.cookingProcessesCalendarDate)).format("dddd ") +
                                    moment(new Date(cookingProcess.cookingProcessesCalendarDate)).format("l")}
                            </span>
                        )}
                        <CalendarIcon className="calendarIcon" onClick={this.handleCalenderButtonClick} />
                    </div>
                </header>
                <Popup
                    visible={showLanguageSelection}
                    onHiding={this.hideLanguageSelection}
                    hideOnOutsideClick={true}
                    width={250}
                    height={500}
                    title={t("SelectLanguage")}
                >
                    <ScrollView
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {languages.map(({ code, name, country_code }) => (
                            <React.Fragment key={code}>
                                <span>
                                    <Button
                                        onClick={() => {
                                            i18n.changeLanguage(code);
                                            this.hideLanguageSelection();
                                            this.updateCustomerLanguage(customersUid, code);
                                        }}
                                        style={{ margin: 5 }}
                                        width={180}
                                    >
                                        <span
                                            style={{ marginRight: 10 }}
                                            className={`flag-icon flag-icon-${country_code}`}
                                        ></span>
                                        {name}
                                    </Button>
                                </span>
                            </React.Fragment>
                        ))}
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { identity, theme, cookingProcess } = state;
    return {
        identity,
        theme,
        cookingProcess,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]))(withRouter(GuestHeader));
