import { Button, SelectBox, TextBox, ValidationGroup } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { Button as TextBoxButton } from "devextreme-react/text-box";
import {
    AsyncRule,
    EmailRule,
    PatternRule,
    RequiredRule,
    StringLengthRule,
    Validator,
} from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { register } from "../../../../actions/identityActions";
import { userActions } from "../../../../actions/userActions";
import { getToast } from "../../../../helpers/requestHelpers";
import eyeSlashIcon from "../../../../img/eye-slash-solid.svg";
import eyeIcon from "../../../../img/eye-solid.svg";
import store from "../../../../store";

const RegisterForm = (props) => {
    const { t } = props;
    const [allUsers, setAllUsers] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [role, setRole] = useState("Admin");
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState([]);
    const validationGroup = useRef(null);

    useEffect(() => {
        store.dispatch(userActions.getAllRoles()).then((response) => {
            setAllRoles(response.roles.map((r) => r.role));
        });
    }, []);

    useEffect(() => {
        if (!props.visible) {
            if (validationGroup && validationGroup.current) {
                validationGroup.current.instance.reset();
            }
            setEmail("");
            setPassword(generatePassword());
            setFirstName("");
            setLastName("");
            setPhone("");
            setOrganisation("");
            setRole("Admin");
            setErrors([]);
        } else {
            store.dispatch(userActions.getAll(props.identity.user)).then((response) => {
                setAllUsers(response.users);
            });
        }
    }, [props.visible]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (email && password && role) {
            const user = {
                username: email,
                password: password,
                role: role,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                organisation: organisation,
            };
            store.dispatch(register(props.identity.user, user)).then((response) => {
                if (!response.registrationStatus.Succeeded) {
                    notify(getToast("Anlegen des Benutzers fehlgeschlagen.", "error"));
                    setErrors(response.registrationStatus.errors);
                } else {
                    notify(getToast("Benutzer anlegen erfolgreich.", "success"));
                    props.hide();
                }
                setIsLoading(false);
            });
        }
    };

    const generatePassword = () => {
        var length = 32,
            charsetLower = "abcdefghijklmnopqrstuvwxyz",
            charsetUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            charsetNumeric = "0123456789",
            charsetAlphaNumeric = "!?-_()",
            retVal = "";
        for (var i = 0, n = charsetLower.length; i < length / 4; ++i) {
            retVal += charsetLower.charAt(Math.floor(Math.random() * n));
        }
        for (i = 0, n = charsetUpper.length; i < length / 4; ++i) {
            retVal += charsetUpper.charAt(Math.floor(Math.random() * n));
        }
        for (i = 0, n = charsetNumeric.length; i < length / 4; ++i) {
            retVal += charsetNumeric.charAt(Math.floor(Math.random() * n));
        }
        for (i = 0, n = charsetAlphaNumeric.length; i < length / 4; ++i) {
            retVal += charsetAlphaNumeric.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    };

    return (
        <React.Fragment>
            <LoadPanel visible={isLoading} container="#dishes" />
            <form onSubmit={handleSubmit} autoComplete="off">
                {props.visible && (
                    <ValidationGroup ref={validationGroup}>
                        <div className={"dx-field"}>
                            <TextBox
                                name="email"
                                mode="email"
                                placeholder={t("Register.Email")}
                                onValueChanged={(e) => setEmail(e.value)}
                                value={email}
                                valueChangeEvent="keyup"
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                    <EmailRule message={t("Ungültige E-Mail-Adresse")} />
                                    <AsyncRule
                                        message={t("E-Mail-Adresse existiert bereits")}
                                        validationCallback={(params) => {
                                            return new Promise((resolve) => {
                                                resolve(!allUsers.find((u) => u.UserName === params.value));
                                            });
                                        }}
                                    />
                                </Validator>
                            </TextBox>
                        </div>

                        <div className={"dx-field"}>
                            <TextBox
                                name="password"
                                mode={showPassword ? "text" : "password"}
                                placeholder={t("Register.Password")}
                                onValueChanged={(e) => setPassword(e.value)}
                                value={password}
                                valueChangeEvent="keyup"
                            >
                                <TextBoxButton
                                    name="showPassword"
                                    style={{ marginRight: 5 }}
                                    options={{
                                        location: "after",
                                        type: "default",
                                        color: "#fff",
                                        icon: showPassword ? eyeSlashIcon : eyeIcon,
                                        onClick: () => {
                                            setShowPassword(!showPassword);
                                        },
                                    }}
                                ></TextBoxButton>
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                    <StringLengthRule min={6} message={t("Passwords must be at least 6 characters.")} />
                                    <PatternRule
                                        message={t("Passwords must have at least one uppercase ('A'-'Z').")}
                                        pattern={/[A-Z]/}
                                    />
                                    <PatternRule
                                        message={t("Passwords must have at least one lowercase ('a'-'z').")}
                                        pattern={/[a-z]/}
                                    />
                                    <PatternRule
                                        message={t("Passwords must have at least one digit ('0'-'9').")}
                                        pattern={/\d/}
                                    />
                                    <PatternRule
                                        message={t("Passwords must have at least one non alphanumeric character.")}
                                        pattern={/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/}
                                    />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="firstName"
                                mode="text"
                                placeholder={t("Register.FirstName")}
                                onValueChanged={(e) => setFirstName(e.value)}
                                value={firstName}
                                valueChangeEvent="keyup"
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="lastName"
                                mode="text"
                                placeholder={t("Register.LastName")}
                                onValueChanged={(e) => setLastName(e.value)}
                                value={lastName}
                                valueChangeEvent="keyup"
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="phone"
                                mode="text"
                                placeholder={t("Telefonnummer")}
                                onValueChanged={(e) => setPhone(e.value)}
                                value={phone}
                                valueChangeEvent="keyup"
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={"dx-field"}>
                            <TextBox
                                name="organisation"
                                mode="text"
                                placeholder={t("Settings.Customer.Company")}
                                onValueChanged={(e) => setOrganisation(e.value)}
                                value={organisation}
                                valueChangeEvent="keyup"
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </TextBox>
                        </div>

                        <div className={"dx-field"}>
                            <SelectBox
                                items={allRoles}
                                name="role"
                                mode="text"
                                placeholder={t("Register.SelectRole") + ":"}
                                onValueChanged={(e) => setRole(e.value)}
                                value={role}
                            >
                                <Validator>
                                    <RequiredRule message={t("Register.ThisFieldIsRequired")} />
                                </Validator>
                            </SelectBox>
                        </div>
                        <div style={{ marginTop: 40 }} className={"dx-field"}>
                            <div
                                className={"dx-field-value"}
                                style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                            >
                                <Button text={t("Abbrechen")} type="normal" onClick={props.hide} />
                                <Button text={t("Register.Save")} type="default" useSubmitBehavior={true} />
                            </div>
                        </div>
                        {errors &&
                            errors.length > 0 &&
                            errors.map((error, index) => {
                                return (
                                    <div style={{ color: "red" }} key={index}>
                                        {t(error.description)}
                                    </div>
                                );
                            })}
                    </ValidationGroup>
                )}
            </form>
        </React.Fragment>
    );
};

function mapStateToProps(state) {
    const { identity, users } = state;
    return {
        identity,
        users,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]), withRouter)(RegisterForm);
