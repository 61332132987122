import { IconButton } from "@material-ui/core";
import { CheckBox, Popup, ScrollView, TreeList } from "devextreme-react";
import { Column } from "devextreme-react/tree-list";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { updateNavigationFavorites } from "../../../../actions/userActions";
import store from "../../../../store";

import DeleteIcon from "@material-ui/icons/Delete";

class EditFavoritesPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favoriteTiles: [],
            allTilesByGroup: [],
        };

        this.updateFavorites = this.updateFavorites.bind(this);
    }

    groupByKey(array, key) {
        return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
        }, {});
    }

    componentDidMount() {
        this.setState({
            favoriteTiles: this.props.favoriteTiles,
            allTilesByGroup: this.groupByKey(
                this.props.allNavigationTiles.filter((tile) => tile.hasRight),
                "navGroup"
            ),
        });

        this.allTilesByGroup = this.groupByKey(
            this.props.allNavigationTiles.filter((tile) => tile.hasRight),
            "navGroup"
        );
    }

    updateFavorites() {
        store.dispatch(updateNavigationFavorites(this.state.favoriteTiles)).then((response) => this.props.update());
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <Popup
                    visible={this.props.visible}
                    height="75vh"
                    width="25vw"
                    minWidth={400}
                    titleRender={() => {
                        return (
                            <h3>
                                {t("Favoriten auswählen")}
                                <IconButton
                                    title={t("Favoriten Löschen")}
                                    style={{ position: "absolute", right: 15, top: 10 }}
                                    children={<DeleteIcon />}
                                    onClick={() =>
                                        store
                                            .dispatch(updateNavigationFavorites([]))
                                            .then((response) => this.props.update())
                                    }
                                />
                            </h3>
                        );
                    }}
                    hideOnOutsideClick={true}
                    onHiding={() => {
                        this.updateFavorites();
                    }}
                    onHidden={this.props.togglePopup}
                >
                    <ScrollView>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                width: "auto",
                                flexDirection: "column",
                                paddingRight: 25,
                            }}
                        >
                            {this.allTilesByGroup &&
                                Object.entries(this.allTilesByGroup).map((entry, index) => {
                                    if (entry[0] !== "Home") {
                                        if (entry[1].length > 0) {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        marginBottom: 20,
                                                        marginLeft: 25,
                                                        width: "20vw",
                                                        minWidth: 300,
                                                        marginRight: 25,
                                                    }}
                                                >
                                                    <h4 h4style={{ marginBottom: 10 }}>{t(entry[0])}</h4>
                                                    <TreeList
                                                        dataSource={entry[1]}
                                                        keyExpr="route"
                                                        showColumnHeaders={false}
                                                        showBorders={true}
                                                        showRowLines={true}
                                                        style={{ marginRight: 25 }}
                                                    >
                                                        <Column
                                                            dataField={"title"}
                                                            caption={"Komponente"}
                                                            calculateDisplayValue={(e) => {
                                                                return t(e.title);
                                                            }}
                                                        />
                                                        <Column
                                                            caption="Ausgewählt"
                                                            width={38}
                                                            cellRender={(e) => {
                                                                return (
                                                                    <CheckBox
                                                                        defaultValue={this.state.favoriteTiles.includes(
                                                                            e.data
                                                                        )}
                                                                        value={this.state.favoriteTiles.includes(
                                                                            e.data
                                                                        )}
                                                                        onValueChanged={(checked) => {
                                                                            if (checked.value === true) {
                                                                                this.setState({
                                                                                    favoriteTiles: [
                                                                                        ...this.state.favoriteTiles,
                                                                                        e.data,
                                                                                    ],
                                                                                });
                                                                            } else {
                                                                                this.setState({
                                                                                    favoriteTiles:
                                                                                        this.state.favoriteTiles.filter(
                                                                                            (fav) => fav !== e.data
                                                                                        ),
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </TreeList>
                                                    {/* {entry[1].map((tile) => {
                                                    return (
                                                        <div
                                                            className="dx-field"
                                                            style={{ marginLeft: 20, width: 380 }}
                                                        >
                                                            <div className="dx-field-label">{"-" + tile.title}</div>
                                                            <div className="dx-field-value">
                                                                <CheckBox
                                                                    defaultValue={this.props.favoriteTiles.includes(
                                                                        tile
                                                                    )}
                                                                    onValueChanged={(e) => {
                                                                        if (e.value === true)
                                                                            this.setState({
                                                                                favoriteTiles: [
                                                                                    ...this.state.favoriteTiles,
                                                                                    tile,
                                                                                ],
                                                                            });
                                                                        else
                                                                            this.setState({
                                                                                favoriteTiles:
                                                                                    this.state.favoriteTiles.filter(
                                                                                        (element) => element !== tile
                                                                                    ),
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })} */}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return <React.Fragment key={index}></React.Fragment>;
                                    }
                                })}
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }
}

export default withTranslation(["dynamicTranslation"])(EditFavoritesPopup);
