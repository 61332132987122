import { Button, HtmlEditor } from "devextreme-react";
import { Item, Toolbar } from "devextreme-react/html-editor";
import notify from "devextreme/ui/notify";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    updateDataProtectionPolicy,
    updateImprint,
    updateWithdrawalNotice,
    updateTermsAndConditions,
} from "../../../../../../actions/licenseActions";
import { getToast } from "../../../../../../helpers/requestHelpers";
import store from "../../../../../../store";
import "./aboutThisShop.scss";

const headerValues = [false, 1, 2, 3, 4, 5];
const alignValues = ["left", "center", "right", "justify"];
const sizeValues = [
    "6pt",
    "7pt",
    "8pt",
    "9pt",
    "10pt",
    "11pt",
    "12pt",
    "14pt",
    "16pt",
    "18pt",
    "20pt",
    "22pt",
    "24pt",
    "26pt",
    "28pt",
    "36pt",
    "48pt",
    "72pt",
];

export default function AboutThisShopTabItem({
    tab,
    onUpdate,
    undoHint,
    redoHint,
    separatorHint,
    headerHint,
    boldHint,
    italicHint,
    strikeHint,
    subscriptHint,
    superscriptHint,
    underlineHint,
    alignHint,
    fontSizeHint,
}) {
    const [formData, setFormData] = React.useState({ ...tab });
    const { t } = useTranslation("dynamicTranslation");

    const onChange = useCallback(
        (e) => {
            setFormData({ ...formData, html: e.value });
        },
        [formData]
    );

    const onSubmit = useCallback(() => {
        onUpdate({ ...formData });
        switch (tab.ID) {
            case 1:
                store.dispatch(updateImprint(formData.html)).then((result) => {
                    if (result && result.data && result.data.status === 200) {
                        notify(getToast(t("Speichern erfolgreich"), "success"));
                    }
                });
                break;
            case 2:
                store.dispatch(updateDataProtectionPolicy(formData.html)).then((result) => {
                    if (result && result.data && result.data.status === 200) {
                        notify(getToast(t("Speichern erfolgreich"), "success"));
                    }
                });
                break;
            case 3:
                store.dispatch(updateWithdrawalNotice(formData.html)).then((result) => {
                    if (result && result.data && result.data.status === 200) {
                        notify(getToast(t("Speichern erfolgreich"), "success"));
                    }
                });
                break;
            case 4:
                store.dispatch(updateTermsAndConditions(formData.html)).then((result) => {
                    if (result && result.data && result.data.status === 200) {
                        notify(getToast(t("Speichern erfolgreich"), "success"));
                    }
                });
                break;
            default:
                break;
        }
    }, [formData, tab.ID, onUpdate, t]);

    const onCancel = useCallback(() => {
        setFormData({ ...tab });
    }, [tab]);

    return (
        <div>
            <HtmlEditor height={500} onValueChanged={onChange} value={formData.html} defaultValue={formData.html}>
                <Toolbar multiline>
                    <Item name="undo" options={undoHint} />
                    <Item name="redo" options={redoHint} />
                    <Item name="separator" options={separatorHint} />
                    <Item name="header" formatValues={headerValues} options={headerHint} />
                    <Item name="bold" options={boldHint} />
                    <Item name="italic" options={italicHint} />
                    <Item name="strike" options={strikeHint} />
                    <Item name="subscript" options={subscriptHint} />
                    <Item name="superscript" options={superscriptHint} />
                    <Item name="underline" options={underlineHint} />
                    <Item name="separator" options={separatorHint} />
                    <Item name="align" formatValues={alignValues} options={alignHint} />
                    <Item name="size" options={fontSizeHint} formatValues={sizeValues} />
                </Toolbar>
            </HtmlEditor>
            <div className="popupActions">
                <Button style={{ marginRight: "10px" }} text={t("Verwerfen")} type="danger" onClick={onCancel} />
                <Button text={t("Speichern")} type="success" onClick={onSubmit} />
            </div>
        </div>
    );
}
