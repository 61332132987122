import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLicenseNumber } from "./actions/licenseActions";
import Backend from "i18next-http-backend";
import { loadLanguage } from "./actions/menuCardAction";
import store from "./store";
import Config from "./Config";

const config = new Config();
i18n.use(Backend)

    .use(initReactI18next)

    .init({
        react: { useSuspense: true },
        ns: ["dynamicTranslation"],
        defaultNS: "dynamicTranslation",
        debug: false,
        load: "currentOnly",
        backend: {
            parsePayload: function (namespace, key, fallbackValue) {
                return { key };
            },
            loadPath: window.location.href.includes("guest")
                ? config.backendHost + "/settings/loadGuestLanguage/{{lng}}"
                : config.backendHost + "/settings/loadLanguage/{{lng}}",
            request: (options, url, payload, callback) => {
                let arr = url.split("/");
                let lng = arr[arr.length - 1];

                if (
                    (localStorage.getItem("user") &&
                        JSON.parse(localStorage.getItem("user")).selectedLanguage &&
                        JSON.parse(localStorage.getItem("user")).selectedLanguage === lng) ||
                    window.location.href.includes("guest")
                ) {
                    try {
                        store.dispatch(loadLanguage(url)).then((result) => {
                            store.dispatch(getLicenseNumber()).then((licenseNumber) => {
                                let licenseNumberString = licenseNumber.replaceAll("-", "").toLowerCase();
                                fetch(
                                    `https://biostranslation.blob.core.windows.net/lang-${lng}/${licenseNumberString}.json`
                                )
                                    .then((response) => response.json())
                                    .then((dynamicTranslationJson) => {
                                        let mergedTranslationJson = Object.assign({}, result, dynamicTranslationJson);
                                        callback(null, {
                                            data: mergedTranslationJson,

                                            status: 200,
                                        });
                                    })
                                    .catch((error) => {
                                        console.error("Error loading dynamic translation file", error);
                                    });
                            });
                        });
                    } catch (e) {
                        callback(null, {
                            status: 500,
                        });
                    }
                } else callback(null);
            },
            // request: (options, url, payload, callback) => {
            //     let arr = url.split("/");
            //     let lng = arr[arr.length - 1];

            //     if (
            //         (localStorage.getItem("user") &&
            //             JSON.parse(localStorage.getItem("user")).selectedLanguage &&
            //             JSON.parse(localStorage.getItem("user")).selectedLanguage === lng) ||
            //         window.location.href.includes("guest")
            //     ) {
            //         try {
            //             store.dispatch(loadLanguage(url)).then((result) => {
            //                 callback(null, {
            //                     data: result,
            //                     status: 200,
            //                 });
            //             });
            //         } catch (e) {
            //             callback(null, {
            //                 status: 500,
            //             });
            //         }
            //     } else callback(null);
            // },
        },
        whitelist: "de",
        keySeparator: false,
        lng: [
            "de",
            "en",
            "fr",
            "ar",
            "ba",
            "bu",
            "ca",
            "ch",
            "cr",
            "cz",
            "da",
            "es",
            "fi",
            "fr",
            "ga",
            "gr",
            "hu",
            "it",
            "la",
            "li",
            "nl",
            "po",
            "ro",
            "ru",
            "sl",
            "so",
            "sw",
            "tu",
        ],
        fallbackLng: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
