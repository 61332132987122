import { Button, Popup } from "devextreme-react";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatGrammage, getGrammageUnit } from "../../../../helpers/units";
import { withTranslation } from "react-i18next";

class FinishPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };

        this.getWeight = this.getWeight.bind(this);
    }

    getWeight() {
        if (this.props.cookingProcess.weight === 0) return "";
        else {
            return (
                formatGrammage(this.props.cookingProcess.weight) +
                " " +
                getGrammageUnit(this.props.cookingProcess.weight)
            );
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Popup
                visible={this.props.show}
                onHiding={this.props.onHiding}
                hideOnOutsideClick={true}
                width="auto"
                height="auto"
                maxWidth={400}
                minWidth={300}
                showCloseButton={true}
                title={t("CookingProcess.finish")}
            >
                <div id="iconPopups">
                    <p>
                        {t("CookingProcess.AreYouSure.first")} {this.getWeight()}{" "}
                        {t(this.props.cookingProcess.Article.name)} {t("CookingProcess.AreYouSure.second")}
                    </p>
                    <div className="popupButtons">
                        <Button text={t("CookingProcess.No")} type="normal" onClick={this.props.onHiding} />
                        <Button
                            text={t("CookingProcess.Yes")}
                            type="default"
                            onClick={this.props.onFinishCookingProcessClick}
                        />
                    </div>
                </div>
            </Popup>
        );
    }
}

FinishPopup.propTypes = {
    show: PropTypes.bool,
    onHiding: PropTypes.func,
    cookingProcess: PropTypes.object,
    onSuccess: PropTypes.func,
};

export default withTranslation(["dynamicTranslation"])(FinishPopup);
