import TabPanel from "devextreme-react/tab-panel";
import React from "react";
import { withTranslation } from "react-i18next";
import {
    getDataProtectionPolicy,
    getImprint,
    getWithdrawalNotice,
    getTermsAndConditions,
} from "../../../../../../actions/licenseActions";
import store from "../../../../../../store";
import "./aboutThisShop.scss";
import AboutThisShopTabItem from "./aboutThisShopTabItem";

class AboutThisShop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            tabs: [
                { ID: 1, name: "Impressum", html: "" },
                { ID: 2, name: "Datenschutz", html: "" },
                { ID: 3, name: "Widerrufsbelehrung", html: "" },
                { ID: 4, name: "Allgemeine Geschäftsbedingungen", html: "" },
            ],
            isLoading: false,
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.itemTitleRender = this.itemTitleRender.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }

    async componentDidMount() {
        if (!this.state.isLoading) {
            this.setState(
                {
                    isLoading: true,
                },
                async () => {
                    try {
                        var updatedTabs = [...this.state.tabs];
                        const [
                            imprintResult,
                            dataProtectionPolicyResult,
                            withdrawalNoticeResult,
                            termsAndConditionsResult,
                        ] = await Promise.all([
                            store.dispatch(getImprint()),
                            store.dispatch(getDataProtectionPolicy()),
                            store.dispatch(getWithdrawalNotice()),
                            store.dispatch(getTermsAndConditions()),
                        ]);

                        var imprintData = JSON.parse(imprintResult.data);
                        if (imprintData.imprint) updatedTabs[0] = { ...updatedTabs[0], html: imprintData.imprint };

                        var dataProtectionPolicyData = JSON.parse(dataProtectionPolicyResult.data);
                        if (dataProtectionPolicyData.dataProtectionPolicy)
                            updatedTabs[1] = { ...updatedTabs[1], html: dataProtectionPolicyData.dataProtectionPolicy };

                        var withdrawalNoticeData = JSON.parse(withdrawalNoticeResult.data);
                        if (withdrawalNoticeData.withdrawalNotice)
                            updatedTabs[2] = { ...updatedTabs[2], html: withdrawalNoticeData.withdrawalNotice };

                        var termsAndConditionsData = JSON.parse(termsAndConditionsResult.data);
                        if (termsAndConditionsData.termsAndConditions)
                            updatedTabs[3] = { ...updatedTabs[3], html: termsAndConditionsData.termsAndConditions };

                        this.setState({
                            tabs: updatedTabs,
                            isLoading: false,
                        });
                    } catch (error) {
                        console.error(error);
                        this.setState({
                            isLoading: false,
                        });
                    }
                }
            );
        }
    }

    itemTitleRender(tab) {
        return <span>{tab.name}</span>;
    }

    onUpdate = (newContent) => {
        var updatedTabs = this.state.tabs.map((tab) =>
            tab.ID === newContent.ID ? { ...tab, html: newContent.html } : tab
        );
        this.setState({ tabs: updatedTabs });
    };

    render() {
        const { selectedIndex, tabs } = this.state;
        const { t } = this.props;
        return (
            <div className="about-this-page">
                <TabPanel
                    dataSource={tabs}
                    selectedIndex={selectedIndex}
                    onOptionChanged={this.onSelectionChanged}
                    itemTitleRender={this.itemTitleRender}
                    itemComponent={(tab) => (
                        <AboutThisShopTabItem
                            tab={tab.data}
                            onUpdate={this.onUpdate}
                            undoHint={{ hint: t("Rückgänig") }}
                            redoHint={{ hint: t("Wiederholen") }}
                            separatorHint={{ hint: t("Trennzeichen") }}
                            headerHint={{ hint: t("Überschrift") }}
                            strikeHint={{ hint: t("Durchgestrichen") }}
                            boldHint={{ hint: t("Fett") }}
                            italicHint={{ hint: t("Kursiv") }}
                            subscriptHint={{ hint: t("Tiefgestellt") }}
                            superscriptHint={{ hint: t("Hochgestellt") }}
                            underlineHint={{ hint: t("Unterstrichen") }}
                            alignHint={{
                                hint: t("Ausrichten"),
                                displayExpr: (e) => t(e),
                                placeholder: t("Ausrichten"),
                            }}
                            fontSizeHint={{ hint: t("Schriftgröße"), placeholder: t("Schriftgröße") }}
                        />
                    )}
                    className="about-this-page_tab-panel"
                />
            </div>
        );
    }

    onSelectionChanged(args) {
        if (args.name === "selectedIndex") {
            this.setState({
                selectedIndex: args.value,
            });
        }
    }
}

export default withTranslation(["dynamicTranslation"])(AboutThisShop);
