import {
    ADD_ARTICLE_TO_CART,
    DELETE_TRANSACTION,
    DELETE_TEMP_TRANSACTION,
    RECEIVE_ORDERTYPES_FOR_ORDER,
    SET_ARTICLES_LOADING,
    SET_LOADING,
    SET_ORDER,
    SET_TRANSACTION,
    SET_ORDER_TYPE,
    SET_ROOT_ARTICLE_GROUPS,
    TOGGLE_SEARCH_POPUP,
    UNSET_ORDER,
    UPDATE_CURRENT_ARTICLES,
    UPDATE_CURRENT_ARTICLE_GROUPS,
    UPDATE_CUSTOMER_ADDRESS_FOR_ORDER,
    UPDATE_CUSTOMER_FOR_ORDER,
    UPDATE_TRANSACTIONS,
    UPDATE_TRANSACTION_AMOUNT,
    UPDATE_ORDER_SUMS,
    UPDATE_CART_SUM,
    UPDATE_ORDER_VALIDATION,
    UPDATE_SELECTED_ARTICLE_GROUP,
    CACHE_ARTICLE_GROUPS,
    OPEN_ARTICLE_DETAILS_POPUP,
    CLOSE_ARTICLE_DETAILS_POPUP,
    SET_SKIP_ARTICLE_DETAILS,
} from "../actions/orderManagementActions";
import { CACHE_ARTICLES } from "../actions/menucardActions/articleAction";

const initialState = {
    isLoading: false,
    isArticlesLoading: false,
    tempOrder: { OrderDetails: [] },
    orderValidation: { fullfilled: false },
    orderSums: [],
    cartSum: 0,
    orderTypes: [],
    rootArticleGroups: [],
    selectedArticleGroup: null,
    articleGroups: [],
    articles: [],
    isSearchPopupOpen: false,
    selectedOrderType: false,
    cachedArticleGroups: {},
    cachedArticles: {},
    articleDetailsPopup: { isVisible: false, articleId: null, skipArticleDetails: false },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ORDER:
            return { ...state, tempOrder: { ...action.payload, OrderDetails: action.payload.OrderDetails || [] } };
        case UNSET_ORDER:
            return initialState;
        case UPDATE_TRANSACTIONS:
            return { ...state, tempOrder: { ...state.tempOrder, Transactions: action.payload } };
        case ADD_ARTICLE_TO_CART:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: [
                        ...(state.tempOrder.Transactions || []),
                        {
                            TotalSalesPrice:
                                action.payload.articleSizes[0].Prices.find(
                                    (p) => p.orderTypesId === state.tempOrder.orderTypeId
                                )?.price1 ?? 0,
                            amount: 1,
                            articleName: action.payload.name,
                            transactionId: 0,
                            size: action.payload.articleSizes[0].Size.name,
                            articlesId: action.payload.articleId,
                            cartItemId: (state.tempOrder.Transactions?.length || 0) + 1,
                            dependencyGroups: action.payload.dependencyGroups,
                        },
                    ],
                },
            };

        case SET_TRANSACTION:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions:
                        state.tempOrder.Transactions?.map((transaction) => {
                            if (transaction.cartItemId === action.payload.cartItemId) {
                                return {
                                    ...transaction,
                                    transactionId: action.payload.transactionId,
                                    TotalSalesPrice: action.payload.TotalSalesPrice,
                                    size: action.payload.size,
                                };
                            }
                            return transaction;
                        }) || [],
                },
            };
        case UPDATE_ORDER_VALIDATION:
            return { ...state, orderValidation: action.payload };
        case UPDATE_ORDER_SUMS:
            return { ...state, orderSums: action.payload };
        case UPDATE_CART_SUM:
            return { ...state, cartSum: action.payload };
        case UPDATE_CUSTOMER_FOR_ORDER:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Customer: action.payload,
                },
            };
        case UPDATE_TRANSACTION_AMOUNT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => {
                        if (transaction.transactionId === action.payload.transactionId) {
                            return { ...action.payload };
                        }
                        return transaction;
                    }),
                },
            };
        case DELETE_TRANSACTION:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: [
                        ...state.tempOrder.Transactions.filter((transaction) => {
                            return transaction.transactionId !== action.payload;
                        }),
                    ],
                },
            };
        case DELETE_TEMP_TRANSACTION:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: [
                        ...state.tempOrder.Transactions.filter((transaction) => {
                            return transaction.cartItemId !== action.payload;
                        }),
                    ],
                },
            };
        case UPDATE_CUSTOMER_ADDRESS_FOR_ORDER:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Customer: {
                        ...state.tempOrder.Customer,
                        Address: action.payload,
                    },
                },
            };
        case RECEIVE_ORDERTYPES_FOR_ORDER:
            return { ...state, orderTypes: action.payload.filter((orderType) => !orderType.isEshop) };
        case SET_ORDER_TYPE:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    orderTypeName: action.payload.name,
                    orderTypesId: action.payload.id,
                },
                selectedOrderType: action.payload,
            };
        case SET_ARTICLES_LOADING:
            return { ...state, isArticlesLoading: action.payload };
        case SET_ROOT_ARTICLE_GROUPS:
            return { ...state, rootArticleGroups: action.payload, articleGroups: action.payload };
        case UPDATE_SELECTED_ARTICLE_GROUP:
            return { ...state, selectedArticleGroup: action.payload };
        case UPDATE_CURRENT_ARTICLE_GROUPS:
            return { ...state, articleGroups: action.payload };
        case UPDATE_CURRENT_ARTICLES:
            return { ...state, articles: action.payload };
        case TOGGLE_SEARCH_POPUP:
            return { ...state, isSearchPopupOpen: action.payload };
        case CACHE_ARTICLE_GROUPS:
            return {
                ...state,
                cachedArticleGroups: {
                    ...state.cachedArticleGroups,
                    [action.payload.parentId]: action.payload.articleGroups,
                },
            };
        case CACHE_ARTICLES:
            return {
                ...state,
                cachedArticles: {
                    ...state.cachedArticles,
                    [action.payload.articleGroupId]: action.payload.articles,
                },
            };
        case SET_SKIP_ARTICLE_DETAILS:
            return {
                ...state,
                articleDetailsPopup: {
                    ...state.articleDetailsPopup,
                    skipArticleDetails: action.payload,
                },
            };
        case OPEN_ARTICLE_DETAILS_POPUP:
            return {
                ...state,
                articleDetailsPopup: {
                    ...state.articleDetailsPopup,
                    isVisible: true,
                    articleId: action.payload.articleId,
                    onSave: action.payload.onSave,
                },
            };
        case CLOSE_ARTICLE_DETAILS_POPUP:
            return {
                ...state,
                articleDetailsPopup: {
                    ...state.articleDetailsPopup,
                    isVisible: false,
                    articleId: action.payload.articleId,
                    onSave: action.payload.onSave,
                },
            };
        default:
            return state;
    }
};
