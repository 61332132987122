import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { List } from "devextreme-react";
import $ from "jquery";
import React, { useState, useEffect, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import "./navigation.scss";
import Star from "@material-ui/icons/Star";

const Navigation = (props) => {
    const [navigationMenu, setNavigationMenu] = useState(undefined);
    const [activeRoute] = useState(props.history.location.pathname);

    const goToPage = (path) => {
        props.history.push(path);
    };

    const buildNavigationSubMenus = useCallback(
        (navigationMenu, allNavigationTiles) => {
            navigationMenu.forEach((navigationTile) => {
                if (
                    props.navigation.pages.some((page) => page.title === navigationTile.title && page.isNavigationPage)
                ) {
                    navigationTile.isNavigationPage = true;
                    navigationTile.navigationTiles = allNavigationTiles.filter(
                        (tile) => tile.hasRight && tile.navGroup === navigationTile.title
                    );
                    buildNavigationSubMenus(navigationTile.navigationTiles, allNavigationTiles);
                }
            });
        },
        [props.navigation.pages]
    );

    const buildNavigationMenu = useCallback(
        (allNavigationTiles) => {
            const navigationMenu = props.navigation.pages[0].navigationTiles.filter(
                (tile) =>
                    tile.hasRight &&
                    (!tile.hideTile ||
                        tile.title === "Navigation" ||
                        tile.title === "Zurück" ||
                        tile.title === "Startseite")
            );
            buildNavigationSubMenus(navigationMenu, allNavigationTiles);
            return navigationMenu;
        },
        [props.navigation.pages, buildNavigationSubMenus]
    );

    useEffect(() => {
        let allNavigationTiles = [];
        let favorites = props.identity.user.Favorites;
        let favoriteTiles = [];

        props.navigation.pages.forEach((page) => {
            if (page.navigationTiles) allNavigationTiles = allNavigationTiles.concat(page.navigationTiles);
        });

        if (Array.isArray(favorites)) {
            favoriteTiles = allNavigationTiles.filter((tile) =>
                favorites.some((element) => element.route === tile.route)
            );
        }

        if (favoriteTiles.length > 0) {
            favoriteTiles = favoriteTiles.map((tile) => {
                tile.isFavorite = true;
                return tile;
            });
        }

        const navigationMenu = buildNavigationMenu(allNavigationTiles);
        navigationMenu.push({
            title: "Favoriten",
            navGroup: "Home",
            hasRight: true,
            icon: Star,
            navigationTiles: favoriteTiles,
            isNavigationPage: true,
        });
        setNavigationMenu(navigationMenu);
    }, [props.identity.user.Favorites, props.navigation.pages, buildNavigationMenu]);

    const navigationListItem = (navigationTile, index, recursionDepth) => {
        const { t } = props;
        const mediumIconStyle = { marginRight: 5, width: "24px", height: "24px", fontSize: "24px" };
        const largeIconStyle = { marginRight: 5, width: "36px", height: "36px", fontSize: "36px" };

        if (!navigationTile.isNavigationPage) {
            return (
                <div>
                    <span
                        onClick={() => {
                            navigationTile.route && goToPage(navigationTile.route);
                        }}
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                        title={t(navigationTile.title)}
                    >
                        <navigationTile.icon
                            icon={navigationTile.iconText ? navigationTile.iconText : ""}
                            style={recursionDepth === 0 ? largeIconStyle : mediumIconStyle}
                        />
                        <span style={{ marginLeft: 15 }}>{t(navigationTile.title)}</span>
                    </span>
                </div>
            );

        } else {
            return (
                <div>
                    <React.Fragment>
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                            }}
                            title={t(navigationTile.title)}
                            onClick={() => {
                                $(`.navItem_${recursionDepth}_${index}`).toggleClass("open");
                            }}
                        >
                            <navigationTile.icon
                                fontSize={recursionDepth === 0 ? "large" : "medium"}
                                icon={navigationTile.iconText ? navigationTile.iconText : ""}
                                size="lg"
                                style={recursionDepth === 0 ? largeIconStyle : mediumIconStyle}
                            />
                            <span style={{ marginLeft: 15 }}>{t(navigationTile.title)}</span>{" "}
                            <span style={{ position: "absolute", right: 5 }}>
                                <KeyboardArrowDown style={{ fontSize: "24px" }} />
                            </span>
                        </span>

                        <div className={`navContainer navItem_${recursionDepth}_${index}`}>
                            {props.open ? nestedNavigationList(navigationTile, recursionDepth) : null}
                        </div>
                    </React.Fragment>
                </div>
            );
        }
    };

    const nestedNavigationList = (navigationTile, recursionDepth) => {
        return (
            <List
                virtualModeEnabled
                items={navigationTile.navigationTiles}
                onItemClick={(e) => {
                    if (!e.itemData.isNavigationPage) {
                        props.toggleNavigation();
                        goToPage(e.itemData.route);
                    }
                }}
                style={{ marginLeft: 20 }}
                expandAllEnabled={false}
                itemRender={(item, idx) => navigationListItem(item, idx, recursionDepth + 1)}
            />
        );
    };

    const { t } = props;
    return (
        <React.Fragment>
            {props.identity.user.Token && (
                <div id="navigation" style={{ width: "350px", boxShadow: "0px 0px 5px #000", backgroundColor: "#eee" }}>
                    {navigationMenu && (
                        <List
                            items={navigationMenu}
                            onItemRendered={(e) => {
                                if (e.itemData.route === activeRoute) {
                                    $(e.itemElement).toggleClass("active-route");
                                }
                            }}
                            onItemClick={(e) => {
                                if (e.itemData.title === "Navigation") {
                                    props.toggleNavigation();
                                } else if (e.itemData.title === "Zurück") {
                                    if (window.location.hash.includes("/kochprozess/")) {
                                        goToPage("/kueche/kochprozesse/p");
                                    } else {
                                        props.history.goBack();
                                    }
                                } else if (e.itemData.title === "Startseite") {
                                    goToPage("/");
                                } else if (e.itemData.isNavigationPage) {
                                    if (!props.open) {
                                        goToPage(e.itemData.route);
                                    }
                                }
                            }}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            itemRender={(item, idx) => navigationListItem(item, idx, 0)}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { identity, navigation } = state;
    return {
        identity,
        navigation,
    };
};

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]), withRouter)(Navigation);
