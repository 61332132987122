import { LoadIndicator } from "devextreme-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { getUserLanguage } from "../../actions/userActions";
import store from "../../store";
import Content from "./content/content";
import Header from "./header/header";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = { languageSet: false };
    }

    async componentDidMount() {
        const { i18n } = this.props;
        if (
            this.props.identity.user &&
            this.props.identity.user.Username != null &&
            this.props.identity.user.selectedLanguage
        ) {
            await i18n.changeLanguage(this.props.identity.user.selectedLanguage);
            this.setState({ languageSet: true });
        } else this.setState({ languageSet: true });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.languageSet && (
                    <React.Fragment>
                        <Header />
                        <Content />
                    </React.Fragment>
                )}
                {!this.state.languageSet && (
                    <div id="themeLoading">
                        <LoadIndicator width={80} height={80} />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { identity } = state;
    return {
        identity,
    };
}

export default compose(connect(mapStateToProps), withTranslation(["dynamicTranslation"]))(Layout);
